/*GENERAL*/
html, body {
	font-family: 'Lato', sans-serif;
}
h1,h2,h3,h4,h5,h6 {
	font-weight: bold;
}
img {
	max-width: 100%;
	height: auto;
}
ul {
  list-style-type: none;
}
section {
	padding: 45px 0 80px 0;
}
.App {
	min-height: 100vh;
}
.heading {
	text-align: center;
	padding-bottom: 40px;
}
.heading h1 {
	margin-top: 40px;
}
.heading h3 {
	margin-top: 40px;
	margin-bottom: 40px;
}
.heading p {
	margin-left: 50px;
	margin-right: 50px;
	margin-top: 80px;
	margin-bottom: 60px;
	font-size: 1.4rem;
}
.r-divider {
	width: 120px;
	height: 2px;
	background: #484C84;
	margin: 0 auto 25px auto;
}
.w-divider {
	width: 120px;
	height: 2px;
	background: #ddd;
	margin: 0 auto 25px auto;
}

/*MENU*/
.overTheTop {
	top: -51px!important;
	position: absolute;
}
.navbar {
	transition: all .5s ease-in-out;
	background: #484C6b;
	font-size: 12px;
	letter-spacing: 3px;
	text-transform: uppercase;
	font-weight: bold;
	border-bottom: 1px solid #ddd;
}
.navbar-nav {
	vertical-align: top;
}
.navbar-collapse {
	text-align: center;
}
.navbar-nav li a {
	border-radius: 3px;
	color: #ddd !important;
}
#menu .nav-link {
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	margin-left: 1rem;
}
#menu .nav {
	display: flex;
}
#menu.show .nav, #menu.collapsing .nav{
	display: inline-block !important;
	float: none;
	transition: all .4s ease-in 0s;
}
.navbar-nav li a:hover, .navbar-nav li a.active {
	color: #484C84 !important;
	background: #ddd;

}
.navbar-toggler {
	border-style: none;
}
.fa.fa-navicon {
	color: #ddd;
}
.button1 {
	font-weight: bold;
	font-size: .7rem;
	text-transform: uppercase;
	color: #eee;
	border: 1px solid #eee;
	margin-top: 40px;
	padding: 18px 18px;
	border-radius: 4px;
	transition: all .4s ease-in 0s;
	text-decoration: none;
	max-width: 80%;
}
.button1:hover {
	background: #ddd;
	color: #484C6B;
	text-decoration: none;
}
@media(min-width: 400px) {
	.button1 {
		padding: 18px 50px;
		font-size: 1rem;
	}
}
/*ABOUT*/
#about {
	padding-top: 100px;
	background: #484C84;
	min-height: 100vh;
}
.profile-picture {
	text-align: center;
	margin: 0 auto;
	float: none;
}
#about .heading h1 {
	font-size: 2rem;
	color: #ddd;
	text-transform: uppercase;
	padding-bottom: 15px;
}
#about .heading h3 {
	color: #111;
	padding-bottom: 40px;
}
#about .heading p:first-of-type {
	font-size: 1.6rem;
	color: #AAA;
}
#about .heading p {
	color: #888;
	padding-left: 60px;
	padding-right: 60px;
}
#about .heading a {
	margin-top: 40px;
	margin-bottom: 40px;
}
/*SKILLS*/
#skills {
	padding-top: 100px;
	min-height: 100vh;
	background: #ddd;
}
#skills .heading h2 {
	color: #484C84;
}
#skills .progress {
	height: 20%;
	margin: 30px 20px;
	background: #ccc;
}
#skills .progress-bar {
	background: #484C84;
	height: 100%;
	-webkit-transition: width .6s ease-out;
	-moz-transition: width .6s ease-out;
	-ms-transition: width .6s ease-out;
	-o-transition: width .6s ease-out;
	transition: width .6s ease-out;
}
#skills .progress h5 {
	text-shadow: 1px 1px 1px #222;
	margin: auto;
	font-size: 1rem;
}
#skills .col-lg-6 {
	margin-bottom: 13px;
}

/*EXPERIENCE*/
#experience {
	padding-top: 100px;
	height: 100%;
	background: #484C84;
}
#experience .heading h2 {
	color: #ddd;
}
.timeline {
	padding: 30px 10px;
	position: relative;
}
.timeline:before {
	top: 0;
	bottom: 0;
	position: absolute;
	content:"";
	width: 5px;
	background-color: #333;
	left: .65rem;
	margin-left: -2.5px;
}
.timeline > li {
	margin-bottom: 20px;
	position: relative;
}
.timeline > li:after {
	clear: both;
}
.timeline > li:before, .timeline > li:after {
	content:" ";
	display: table;
}
.timeline li .timeline-badge {
	color: #484C84;
	width: 50px;
	line-height: 50px;
	font-size: 20px;
	text-align: center;
	position: absolute;
	top: 16px;
	left: 0;
	margin-left: -25px;
	background-color: #bbb;
	border-radius: 50%;
	z-index:1;
}
.timeline-panel-container {
	width: 95%;
	float: right;
}
.timeline-panel-container-inverted {
	width: 95%;
	float: right;
}
.timeline-panel {
	width: 90%;
	float: right;
	border: 1px solid #d5d5d5;
	border-radius: 7px;
	padding: 20px 25px;
	position: relative;
	box-shadow: 0 1px 6px rgba(0,0,0,.2);
	background: #ddd;
}
.timeline-panel-container-inverted .timeline-panel {
	float: right;
	margin-left: 40px;
}
.timeline-panel:after {
	position: absolute;
	top: 27px;
	right: auto;
	left: -14px;
	display: inline-block;
	border-top: 14px solid transparent;
	border-left: 0 solid #ddd;
	border-right: 14px solid #ddd;
	border-bottom: 14px solid transparent;
	content:" ";
}
.timeline-panel-container-inverted .timeline-panel:after {
	border-left-width: 0;
	border-right-width: 14px;
	left: -14px;
	right: auto;
}
.timeline-heading h3 {
	text-transform: uppercase;
	margin-top: 5px;
	font-size: 22px;
}
.timeline-heading h4 {
	font-size: 19px;
}
@media(min-width: 370px) {
	.timeline li .timeline-badge {
		z-index: 0;
	}
}
@media(min-width: 900px) {
	#about .heading h1 {
		font-size: 2.5rem;
	}
	#skills .progress h5 {
		font-size: 1.25rem;
	}
	.timeline:before {
		left: 50%;
	}
	.timeline-panel {
		margin-right: 40px;
		float: right;
	}
	.timeline-panel:before {
		left: 50%;
	}
	.timeline li .timeline-badge {
		left: 50%;
	}
	.timeline-panel-container-inverted .timeline-panel {
		float: left;
	}
	.timeline-panel-container-inverted {
		width: 50%;
	}
	.timeline-panel-container {
		width: 50%;
		float: left;
	}
	.timeline-panel:after {
		border-left-width: 14px;
		border-right-width: 0;
		left: auto;
		right: -14px;
	}
	.formation-block h3 {
		font-size: 35px!important;
	}
}
/*FORMATION*/
#formation {
	padding-top: 100px;
	min-height: 100vh;
	background: #ddd;
}
#formation .heading h2{
	color: #484C84;
}
.formation-block {
	width: 80%;
	text-align: center;
	margin: 0 auto;
	padding: 30px;
	background: #bfbfbf;
	border-radius: 10px;
	border: 2px solid #aaa;
	margin-bottom: 20px;
}
[class*="reveal-"] {
	opacity: 0!important;
	transform: translateY(-20px);
}
.reveal-visible {
	opacity: 1!important;
	transform: translateY(0);
	transition: .3s
}
.reveal-2 {
	transition-delay: .2s;
}
.reveal-3 {
	transition-delay: .3s
}
.formation-block h5 {
	color: #888;
	font-size: 15px;
	margin-bottom: 15px;
}
.formation-block .fa {
	font-size: 40px;
}
.formation-block h3 {
	color: #484C84;
	font-size: 30px;
}
.formation-block h4 {
	margin-bottom: 20px;
}
.formation-block h4 {
	font-weight: bold;
}

/*PORTFOLIO*/
#portfolio {
	padding-top: 100px;
	min-height: 100vh;
	background: #484C84;
}
#portfolio .heading h2 {
	color: #ddd;
}
#portfolio .thumbnail img {
	width: 100%;
	height: 100%;
	filter: grayscale(100%);
	-webkit-filter: grayscale(100%);
	transition: all .3s ease-in-out 0s;
}
#portfolio .thumbnail img:hover {
	filter: none;
	-webkit-filter: none;
}
#portfolio .projets{
	transition: all .3s ease-in-out 0s;
	width: auto;
	padding: 0px;
	border-radius: 4px;
	border: 2px solid #aaa;
	margin: 10px;
	position: relative;
}
#portfolio .projets:hover{
	border-color: #333
}
#portfolio .corner-ribbon {
	z-index: 2;
	width: 100px;
	height: 100px;
	overflow: hidden;
	position: absolute;
	top: -10px;
	left: -10px;
}
#portfolio .corner-ribbon span {
	position: absolute;
	display: block;
	width: 150px;
	padding: 10px 0;
	background-color: #e81;
	box-shadow: 0 5px 10px rgba(0,0,0,.1);
	color: #eee;
	font: 700 18px/1 'Lato', sans-serif;
	text-shadow: 0 1px 1px rgba(0,0,0,.2);
	text-transform: uppercase;
	text-align: center;
	right: -15px;
	top: 20px;
	transform: rotate(-45deg);
}
#portfolio .corner-ribbon.android span {
  background-color: #1b9;
}
#portfolio .corner-ribbon.extension span {
  background-color: #FF4B4B;
}

/*CERTIFICATS*/
#certif {
	padding-top: 100px;
	min-height: 100vh;
	background: #ddd;
}
#certif h3 {
	position: absolute;
	right: 15%;
	left: 15%;
	color: #484C84;
}
#certif .carousel-caption {
	bottom: 10px!important;
}
#certif .carousel-caption p {
	background: #DDDDDD80;
	line-height: 2.1rem;
}
#certif .carousel-caption p,
#certif .heading h2 {
	color: #484C84;
	font-size: 2rem;
}
.carousel-control-prev,
.carousel-control-next,
.carousel-control-prev-icon,
.carousel-control-next-icon {
	color: #525798!important;
	font-size: 45px!important;
}
.carousel-control-prev:hover,
.carousel-control-next:hover,
.carousel-control-prev-icon:hover,
.carousel-control-next-icon:hover {
	color: #525798!important;
	font-size: 60px!important;
}

/*CAROUSEL*/
.carousel-indicators {
	bottom: 0;
}
.carousel-indicators li {
	border-color : #484C84;
	border-bottom: none;
	border-top: none;
}
.carousel-indicators li.active {
	background-color: #484C84;
}
.carousel .carousel-item {
	padding: 40px 30px;
}
.carousel .carousel-item h3 {
	color: #484C84;
	font-style: italic;
}
@media (max-width: 900px) {
	.carousel .carousel-item h3 {
		font-size: 1rem;
	}
	.carousel-caption p {
		display: none;
	}
}

/*CONTACT*/
#contact {
	padding-top: 80px;
	min-height: 100vh;
	background-color: #484c84;
}
#contact .heading {
	text-align: center;
}
#contact .heading h2 {
	color: #ddd;
	padding-top: 20px;
}
#contact-form {
	font-size: 20px;
	background: #ddd;
	padding: 40px;
	border-radius: 10px;
	margin-bottom: 24px;
}
label {
	color: #000;
	width: 90%;
}
.blue {
	color: #0069d6;
}
.purple {
	color: #484C6B;
	font-size: 1.5rem;
	text-align: center;
}
.form-control {
	height: 50px;
	font-size: 18px;
	font-style: bold;
}
#message {
	height: 100px;
}
.comments {
	font-style: italic;
	font-size: 16px;
	color: #d82c2e;
	height: 25px;
}
.buttonForm {
	margin: 40px auto 30px;
	display: block;
	border: 1.8px solid #aaa;
	background: #484C6B;
	color: #eee;
	width: 100%;
	font-weight: bold;
	text-transform: uppercase;
	padding: 18px;
	border-radius: 5px;
	transition: all .3s ease-in 0s;
	cursor: pointer;
}
.buttonForm:hover {
	background-color: #333;
	border-color: #484C84;
	color: #aaa;
}
.thank-you {
	display: block;
	text-align: center;
	margin-top: 15px;
	margin-bottom: 50px;
	font-weight: bold;
	font-size: 24px;
	color: green;
}

/*FOOTER*/
footer {
	background: #333;
	padding: 20px 0 40px;
}
footer .fa {
	color: #ddd;
	font-size: 25px;
	margin: 20px;
	height: 30px;
}
footer .fa:hover {
	color: #484c84;
	font-size: 27px;
}
footer h5 {
	color: #ddd;
	font-weight: normal;
}
.back {
	margin-left: 40%;
	background: #fff;
}
.back:hover{
	background: #484C84;
	color: #DDD;
}
.htb-badge {
	text-align: center;
	margin: 50px auto;
	float: none;
}